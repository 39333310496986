import React, { Component } from 'react';
import { Button, Breadcrumb, BreadcrumbItem, Card, CardBody, CardColumns, CardImg, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import ReactGA from 'react-ga';
import ProjectsData from '../data/ProjectsData.json'

class Projects extends Component {

  componentDidMount() {
    ReactGA.pageview('/projects');
  }

  render() {
    const projects = ProjectsData.map((project, key) => (
      <Card>
        <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=Image here&w=318&h=180" alt="Card image cap" />
        <CardBody>
          <CardTitle>{project.name}</CardTitle>
          <CardSubtitle>{project.shortDescription}</CardSubtitle>
          <CardText>{project.description}</CardText>
          <Button>Button</Button>
        </CardBody>
      </Card>
    ));

    return (
      <div>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
          <BreadcrumbItem active tag="span">Projects</BreadcrumbItem>
        </Breadcrumb>
        <div className='App-coming-soon'>COMING SOON</div>
        {/* <CardColumns>
          { projects }
        </CardColumns> */}
      </div>
    );
  }
}

export default Projects;


