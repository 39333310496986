import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ReactGA from 'react-ga';

class Home extends Component {

  componentDidMount() {
    ReactGA.pageview('/home');
  }

  render() {
    return (
      <div>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem active tag="span">Home</BreadcrumbItem>
        </Breadcrumb>
        <div className='App-coming-soon'>COMING SOON</div>
      </div>  
    );
  }
}

export default Home;