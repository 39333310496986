import React, { Component } from 'react';
import ReactGA from 'react-ga';

class NotFound extends Component {

  componentDidMount() {
    const pathname = window.location.pathname;
    const search = window.location.search;
    ReactGA.pageview(`/notfound ${ pathname ? pathname : ''}${search ? search : ''}`);
  }

  render() {
    return (
      <div>
        Sorry, page not found!
      </div>
    );
  }
}

export default NotFound;
