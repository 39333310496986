import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ReactGA from 'react-ga';

class Contact extends Component {

  componentDidMount() {
    ReactGA.pageview('/contact');
  }

  render() {
    return (
      <div>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
          <BreadcrumbItem active tag="span">Contact</BreadcrumbItem>
        </Breadcrumb>
        <div className='App-coming-soon'>COMING SOON</div>
        {/* Contact */}
      </div>
    );   
  }
}

export default Contact