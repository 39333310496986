import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ReactGA from 'react-ga';

class About extends Component {

  componentDidMount() {
    ReactGA.pageview('/about');
  }

  render() {
    return (
      <div>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
          <BreadcrumbItem active tag="span">About</BreadcrumbItem>
        </Breadcrumb>
        <div className='App-coming-soon'>COMING SOON</div>
        {/* About */}
      </div>
    );
  }
}

export default About;
