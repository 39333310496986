import React, { Component } from 'react';
import { Button, Breadcrumb, BreadcrumbItem, Card, CardBody, CardColumns, CardImg, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import ReactGA from 'react-ga';
import MinigamesData from '../data/MinigamesData.json'

class Minigames extends Component {

  componentDidMount() {
    ReactGA.pageview('/minigames');
  }

  render() {
    const minigames = MinigamesData.map((minigame, key) => (
      <Card>
        <CardBody>
          <CardTitle>{minigame.name}</CardTitle>
          <CardSubtitle>{minigame.shortDescription}</CardSubtitle>
          <CardText>{minigame.description}</CardText>
          <Button>Button</Button>
        </CardBody>
      </Card>
    ));

    return (
      <div>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
          <BreadcrumbItem active tag="span">Minigames</BreadcrumbItem>
        </Breadcrumb>
        <CardColumns>
          { minigames }
        </CardColumns>
      </div>
    );
  }
}

export default Minigames;


