import React, { Component } from 'react';
import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap';
import { Router, Link, Location } from '@reach/router';
import ReactGA from 'react-ga';
import posed, { PoseGroup } from 'react-pose';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Logo from './images/Logo.png';
import NotFound from './pages/NotFound';
import Minigames from './pages/Minigames';
import Projects from './pages/Projects';
import './App.css';

// Initialize Google Analytics
ReactGA.initialize('UA-136783297-1');
//ReactGA.pageview(window.location.pathname + window.location.search);

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: 300 },
  exit: { opacity: 0 }
});

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <PoseGroup>
        <RouteContainer key={location.key}>
          <Router location={location}>{children}</Router>
        </RouteContainer>
      </PoseGroup>
    )}
  </Location>
);

class App extends Component {
  constructor(props, context) {
    super(props, context);

    this.toggleMenu = this.toggleMenu.bind(this);

    this.state = {
      isMenuOpen: false
    };
  }

  toggleMenu() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    });
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <Navbar color="light" light expand="md">
            <Link to="/"><img src={Logo} alt="OldCacto" className="App-logo"/></Link>
            <NavbarToggler onClick={this.toggleMenu} />
            <Collapse isOpen={this.state.isMenuOpen} navbar>
              <Nav className="ml-auto" navbar>
                {/* <NavItem><Link to="/minigames" className="nav-link">Minigames</Link></NavItem> */}
                <NavItem><Link to="/projects" className="nav-link">Projects</Link></NavItem>
                <NavItem><Link to="/about" className="nav-link">About</Link></NavItem>
                <NavItem><Link to="/contact" className="nav-link">Contact</Link></NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </header>

        <main className="App-main">
          <PosedRouter>
            <Home path="/" />
            <Minigames path="/minigames" />
            <Projects path="/projects" />
            <About path="/about" />
            <Contact path="/contact" />
            <NotFound default />
          </PosedRouter>
        </main>

        <footer className="App-footer">
          <Nav>
            <NavItem><Link to="/">> Home</Link></NavItem>
            {/* <NavItem><Link to="/minigames">Minigames</Link></NavItem> */}
            <NavItem><Link to="/projects">Projects</Link></NavItem>
            <NavItem><Link to="/about">About</Link></NavItem>
            <NavItem><Link to="/contact">Contact</Link></NavItem>
          </Nav>
          <p className="App-footer-copyright">Copyright 2019 - v_0.1</p>
        </footer>
      </div>
    );
  }
}

export default App;
